.form {
  label[for="password"] {
    width: 100%;
  }
}

.passwordLabel {
  display: flex;
  justify-content: space-between;
}

.forgotPassword {
  font-size: inherit;
}