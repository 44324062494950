.dFlex {
  display: flex;
}

.dFlexColumn {
  display: flex;
  flex-direction: column;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.imgResponsive {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
}