@use '@carbon/layout';
@use '@carbon/type';

.container {
  // reset top margin
  margin-top: -(layout.$spacing-04);
}

.group:not(:first-of-type) {
  margin-top: layout.$spacing-06
}

.groupTitle {
  @include type.type-style('heading-01');
  margin-bottom: layout.$spacing-04;
}

.url {
  word-break: break-all;
}
