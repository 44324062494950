@use '@carbon/layout';

.sm {
  padding: 0;
}

.md {
  padding: layout.$spacing-02 0;
}

.lg {
  padding: layout.$spacing-03 0;
}