@use '@carbon/type';
@use '@carbon/layout';
@use '@carbon/react/scss/colors';
@use '@carbon/grid';

$headerElementHeight: 3rem;
$warningElementHeight: calc(var(--warningElementHeight) * 1px); // convert number to pixel

// overriding header tooltip bg color
// because the navigation is dark themed while the content is gray
// which means the dark theme tooltip bg blends into the grey content bg
:global(.cds--header__global .cds--popover-content) {
  background-color: colors.$white;
}
:global(.cds--header__global .cds--popover-caret) {
  background-color: colors.$white;
}

:global(.cds--side-nav__overlay) {
  top: calc($headerElementHeight + $warningElementHeight);
}

.header {
  top: $warningElementHeight;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: inherit;
  max-width: 99rem;
  margin-left: auto;
  margin-right: auto;
}

a.appName {
  font-size: type.type-scale(4);
  font-weight: 600;
  border: none;
}

.loader {
  width: 25px;
  height: 25px;
  align-self: center;
  margin-right: layout.$spacing-05;
}

.panel {
  position: absolute;
  display: none;
  height: fit-content;
  width: max-content;
  border-left: none !important;

  &.expanded {
    display: block
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $headerElementHeight); // view height - header height
  padding: 0.5rem;
  max-width: 99rem;
  margin-top: $headerElementHeight; // header height
  margin-left: auto;
  margin-right: auto;
  
  @include grid.breakpoint-up('md') {
    background-color: inherit;
    padding: 1rem;
  }

  @include grid.breakpoint-up('max') {
    background-color: inherit;
    padding: 2rem 1rem;
  }

  &.hasWarning {
    min-height: calc(100vh - $headerElementHeight - $warningElementHeight); // view height - header height - warning height
    margin-top: calc($headerElementHeight + $warningElementHeight); // header height + warning height
  }
}

.link a:focus {
  border: 2px solid transparent !important;

  &:after {
    border-bottom: 3px solid var(--cds-border-interactive, #0f62fe) !important;
  }
}

.logo {
  width: 20px;
  margin-right: layout.$spacing-04;
}

.sideNav {
  top: calc($headerElementHeight + $warningElementHeight);
}

.warningMessageWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8100;
}

.warningMessage {
  width: 100%;
  max-width: 100%;
  border: none !important;

  &::before {
    border: none !important;
  }

  :global(.cds--inline-notification__details) {
    width: 100%;
    max-width: 99rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 layout.$spacing-05;
  }
}