.container {
  width: 100%;

  &.vertical {
    height: 450px;
  }

  &.horizontal {
    height: 350px;
  }
}