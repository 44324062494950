@use '@carbon/layout';

.heading {
  margin-bottom: layout.$spacing-06;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: layout.$spacing-05 layout.$spacing-02;
  border-top: solid thin var(--cds-border-subtle);

  &:first-of-type {
    border-top: none;
    padding: 0 layout.$spacing-02 layout.$spacing-05;
  }

  &:last-of-type {
    padding: layout.$spacing-05 layout.$spacing-02 0;
  }
}