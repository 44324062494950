.tabList {
  :global([role="tablist"]) {
    width: 100%;
  }
}

.tab {
  max-width: 100% !important;
}

.tabPanel {
  padding-left: 0;
  padding-right: 0;
}

.tabsSkeleton > ul {
  width: 100%;
}

.tabsSkeleton > ul li :global(.cds--tabs__nav-link) {
  width: 100% !important; 
}