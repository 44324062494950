@use '@carbon/grid';
@use '@carbon/colors';

.container {
  height: 1px;
  margin: 1rem 0;
  background-color: colors.$gray-30;
  color: colors.$gray-70;
  border: 0;

  @include grid.breakpoint-up('md') {
    margin-top: 1.5rem;
  }
}