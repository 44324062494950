@use '@carbon/layout';

.container {
  width: 100%;

  :global(.cds--data-table-header) {
    padding-bottom: layout.$spacing-04;
    padding-right: layout.$spacing-05;
  }
}

.skeleton {
  width: 100%;
}

.tableBody {
  position: relative;
}

.checkboxSkeleton {
  width: 30px;
}

.cellSkeleton {
  width: 120px !important;
}

.cell {
  white-space: nowrap;
}