@use '@carbon/layout';
@use '@carbon/colors';

.record {
  margin-top: layout.$spacing-05;
  padding-top: layout.$spacing-05;
  border-top: solid thin colors.$cool-gray-10;
}

.condensed {
  margin-top: layout.$spacing-03;
  padding-top: 0;
  border-top: none;
}