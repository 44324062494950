@use '@carbon/type';
@use '@carbon/layout';
@use '@carbon/themes';

.container p {
  @include type.type-style('body-01');
}

.headingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: layout.$spacing-06;
}

.heading {
  @include type.type-style('heading-03');
  margin-left: layout.$spacing-04;
}

.icon {
  color: themes.$support-success;
}
