@use '@carbon/layout';
@use '@carbon/grid';
@use '@carbon/type';

.heading {
  @include type.type-style('heading-01');
}

.filters {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: layout.$spacing-04;

  @include grid.breakpoint-up('md') {
    grid-template-columns: minmax(200px, 300px) 150px;
    column-gap: layout.$spacing-04 ;
  }
}

.error {
  margin-top: layout.$spacing-04;
}

.yearlyStats {
  margin-top: layout.$spacing-06;
}

.monthlyStats {
  margin-top: layout.$spacing-06;

  @include grid.breakpoint-up('md') {
    margin-top: layout.$spacing-08;
  }
}