@use '@carbon/layout';

.container {
  :global(.cds--modal-header) {
    margin-bottom: layout.$spacing-04;
  }
}

.visibleOverflow {
  :global(.cds--modal-container) {
    overflow: visible;
  }

  :global(.cds--modal-content){
    overflow-y: visible;
  }
}