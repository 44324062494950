@use '@carbon/layout';

.heading {
  margin-bottom: layout.$spacing-06;
}

.editButton {
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  margin-left: layout.$spacing-03;
}

.editLabel {
  margin-right: layout.$spacing-01;;
}