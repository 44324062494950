@use '@carbon/layout';
@use '@carbon/grid';
@use '@carbon/type';

.pageHeading {
  margin-bottom: layout.$spacing-04;
}

.card {
  // 48px is header hight and (2 * layout.$spacing) is padding around content
  min-height: calc(100vh - 2 * layout.$spacing-04 - 48px);

  @include grid.breakpoint-up('md') {
    min-height: calc(100vh - 2 * layout.$spacing-06 - 48px);
  }
}

.tabList {
  :global([role="tablist"]) {
    width: 100%;
  }
}

.tabPanel {
  padding-left: 0;
  padding-right: 0;
}

.tabsSkeleton > ul {
  width: 100%;
}

.tabsSkeleton > ul li:nth-child(-n+2) {
  display: none !important;
}

.tabsSkeleton > ul li :global(.cds--tabs__nav-link) {
  width: 100% !important; 
}