@use '@carbon/type';
@use '@carbon/layout';
@use '@carbon/colors';
@import '@styles/mixins.scss';

.name {
  @include type.type-style('heading-03');
}

.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: layout.$spacing-03;
  column-gap: layout.$spacing-06;
  margin-top: layout.$spacing-05;

  &.condensed {
    row-gap: layout.$spacing-02;
    margin-top: layout.$spacing-03;
  }
}

.field {
  color: colors.$gray-60;
}