@use '@carbon/layout';

.noStyle {
  color: inherit !important;
  text-decoration: none !important;
}

.iconBefore {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;

  :global(.cds--link__icon) {
    margin-left: 0;
    margin-right: layout.$spacing-03;
  }
}