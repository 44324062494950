@use '@carbon/grid';

$imageWidth: calc(var(--imageWidth) * 1px);

.container {
  width: 100%;

  @include grid.breakpoint-up('md') {
    flex: 1 0 250px;
    width: $imageWidth;
    max-width: $imageWidth;
  }
}

.skeleton {
  width: 100%;
  height: 200px;

  @include grid.breakpoint-up('md') {
    width: 200px;
  }
}

.placeholderImage {
  margin: 0 auto;
}

.gallery {
  width: 100%;

  :global(.image-gallery-bullets) {
    bottom: 10px;
  }

  :global(.image-gallery-image) {
    object-fit: cover;
    min-height: 200px;
  }

  :global(.image-gallery-slide) {
    display: flex !important;
    align-items: flex-end;
    max-height: 350px;

    @include grid.breakpoint-up('md') {
      max-height: 200px;
    }
  }

  :global(.image-gallery-bullet) {
    margin: 4px;
    padding: 3px;
  }

  :global(.image-gallery-fullscreen-button) {
    padding: 5px;
    margin-right: 5px;
    transform: scale(0.8);
  }

  :global(.image-gallery-right-nav), :global(.image-gallery-left-nav) {
    top: 0;
    transform: scale(0.5);
    padding-left: 0;
    padding-right: 0;
  }

  :global(.image-gallery-right-nav) {
    right: -5px;
  }

  :global(.image-gallery-left-nav) {
    left: -5px;
  }
}

.fullscreen {
  @include grid.breakpoint-up('lg') {
    :global(.image-gallery-image) {
      width: auto;
      min-width: 200px;
    }
  }
}