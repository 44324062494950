@use '@carbon/layout';
@use '@carbon/grid';

.container {
  display: flex;
  flex-direction: column;

  @include grid.breakpoint-up('lg') {
    display: grid;
    grid-template-columns: 1fr 275px;
    column-gap: layout.$spacing-04;
  }
}

.map {
  width: 100%;
  height: 300px;
  margin-bottom: layout.$spacing-06;

  @include grid.breakpoint-up('lg') {
    height: 600px;
    margin-bottom: 0;
  }
}

.error {
  margin-bottom: layout.$spacing-04;
}