@use '@carbon/colors';
@use '@carbon/type';
@use '@carbon/layout';

.container {
  border: solid thin colors.$cool-gray-10;
}

.title {
  display: flex;
  align-items: center;
  padding: layout.$spacing-04;
  background: colors.$cool-gray-10;
  border-bottom: solid thin colors.$cool-gray-20;
  @include type.type-style('heading-01');
}

.icon {
  display: inline-flex;
  margin-right: layout.$spacing-04;
}

.body {
  padding: 1rem;
}