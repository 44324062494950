@use '@carbon/type';

.level-1 {
  @include type.type-style('heading-03');
}

.level-2 {
   @include type.type-style('heading-02');
}

.level-3 {
  @include type.type-style('heading-01');
}
