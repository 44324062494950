@use '@carbon/type';
@use '@carbon/layout';

.container {
  width: 100%;
  max-width: 100%;
}

.text {
  @include type.type-style('body-compact-01');
}

.button {
  margin-left: layout.$spacing-02;
}
