@use '@carbon/grid';

.wrapper {
  position: relative;
  display: flex;
  width: 100%;

  @include grid.breakpoint-up('md') {
    margin: auto;
  }
}

.container {
  width: 100%;
}