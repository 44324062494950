@use '@carbon/grid';

.chart {
  width: 100%;
  display: flex;
  align-items: center;

  @include grid.breakpoint-up('md') {
    width: 350px;
  }
}

.chartContainer {
  width: 180px;
  height: 150px;
}

.chartSkeleton {
  width: 130px;
  height: 130px;
}

.legend {
  flex: 1 0 auto;
}

.text {
  font-weight: bold;
}

.label {
  position: relative;
  padding-left: 15px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    height: 6px;
    width: 10px;
    display: block;
    background-color: var(--color);
  }
}