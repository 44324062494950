@use '@carbon/grid';
@use '@carbon/type';

.fields {
  display: grid;
  row-gap: var(--cds-stack-gap, 0);
  padding-left: 0;
  padding-right: 0;
}

.column {
  @include grid.breakpoint-down('lg') {
    margin-left: 0;
    margin-right: 0;
  }

  @include grid.breakpoint-up('lg') {
    margin-left: 0;

    // order inputs on smaller screen. Move right part completely below left side
    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        order: 2 * $i - 1;
      }
      &:nth-child(#{$i + 8}) {
        order: 2 * $i;
        margin-right: 0;
      }
    }
  }
}

.description {
  margin-bottom: 0;
  @include type.type-style('body-01');
}

.comboBox {
  display: flex;

  @include grid.breakpoint-up('lg') {
    display: grid;
    grid-template-columns: 1fr 180px;
  }
}