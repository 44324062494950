@use '@carbon/layout';

$space-list: (
  '0': 0,
  '1': layout.$spacing-01,
  '2': layout.$spacing-02,
  '3': layout.$spacing-03,
  '4': layout.$spacing-04,
  '5': layout.$spacing-05,
  '6': layout.$spacing-06,
  '7': layout.$spacing-07,
  '8': layout.$spacing-08,
);
$direction-list: (
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

// generate margins and paddings
@each $index, $space in $space-list {
  // for all sides
  .m#{$index} {
    margin: #{$space};
  }
  .p#{$index} {
    padding: #{$space};
  }

  // for horizontal
  .mx#{$index} {
    margin: 0 #{$space};
  }
  // for vertical
  .my#{$index} {
    margin: #{$space} 0;
  }

  // for horizontal
  .px#{$index} {
    padding: 0 #{$space};
  }
  // for vertical
  .py#{$index} {
    padding: #{$space} 0;
  }

  // for each directions
  @each $abbr, $direction in $direction-list {
    .m#{$abbr}#{$index} {
      margin-#{$direction}: #{$space};
    }
    .p#{$abbr}#{$index} {
      padding-#{$direction}: #{$space};
    }
  }
}
