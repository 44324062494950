@use '@carbon/type';
@use '@carbon/layout';

.heading {
  @include type.type-style('heading-01');
}

.item {
  :global(.cds--accordion__content) {
    padding-top: layout.$spacing-04;
    padding-right: 0;
  }
}