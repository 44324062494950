@use '@carbon/type';
@use '@carbon/grid';
@use '@carbon/layout';
@use '@carbon/colors';
@import '@styles/mixins.scss';

.card {
  padding: 1rem;

  @include grid.breakpoint-up('md') {
    padding: 1rem 2rem;
  }
}

.container {
  display: flex;

  @include xs {
    flex-direction: column;
  }
}

.info {
  flex: 1;
  margin-left: layout.$spacing-05;

  @include xs {
    margin-left: 0;
    margin-top: layout.$spacing-05;
  }

  @include grid.breakpoint-up('md') {
    margin-left: layout.$spacing-07;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerRight {
  display: flex;
  align-items: center;
  margin-left: layout.$spacing-04;
  flex-shrink: 0;
}

.name {
  @include type.type-style('heading-03');
}

.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: layout.$spacing-02;
  column-gap: layout.$spacing-06;
  margin-top: layout.$spacing-03;
}

.field {
  color: colors.$gray-60;
}

.tag {
  font-size: 0.65rem;
  min-height: 1rem;
  padding: 0 0.4rem;
  margin: 0;
}