@use '@carbon/grid';
@import '@styles/mixins.scss';

.figure {
  display: block;
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid thin #eee;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
  }

  @include xs {
    width: 100%;
    height: auto;
    border-radius: 0;
    border: none;

    img {
      height: auto;
      object-fit: initial;
    }
  }

  @include grid.breakpoint-up('md') {
    width: 130px;
    min-width: 130px;
    height: 130px;
  }
}

.skeleton {
  width: 100px;
  height: 100px;

  @include xs {
    width: 100%;
    height: 200px;
  }

  @include grid.breakpoint-up('md') {
    width: 130px;
    height: 130px;
  }
}