@use '@carbon/layout';

.container {
  flex-shrink: 0;
}

.tag {
  margin-left: layout.$spacing-02;

  &:first-child{
    margin-left: 0;
  }

  &:last-child{
    margin-right: 0;
  }
}