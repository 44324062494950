@use '@carbon/layout';
@use '@carbon/grid';

.refreshButton {
  min-height: auto;
  margin-left: layout.$spacing-04;

  @include grid.breakpoint-down('md') {
    width: 40px;
    height: 40px;
    align-items: center;
    padding: 0;
  }
}

.toolbar {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: layout.$spacing-04;
  width: 100%;
  padding-left: layout.$spacing-05;
  padding-right: layout.$spacing-05;
  padding-bottom: layout.$spacing-05;
  z-index: 100;

  @include grid.breakpoint-up('md') {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.filterWrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: layout.$spacing-04;
  align-items: end;

  @include grid.breakpoint-up('md') {
    grid-template-columns: minmax(230px, 350px) minmax(150px, 220px) minmax(150px, 220px);
    column-gap: layout.$spacing-03;
  }

  @include grid.breakpoint-up('lg') {
    column-gap: layout.$spacing-05;
  }
}

.filterRefresh {
  @include grid.breakpoint-down('md') {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr auto;
  }
}

.datePicker {
  :global(.cds--date-picker), :global(.cds--date-picker-container), :global(.cds--date-picker__input) {
    width: 100% !important;
  }
}