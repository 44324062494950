@use '@carbon/layout';
@use '@carbon/type';

.container:not(:first-of-type) {
  margin-top: layout.$spacing-06
}

.title {
  @include type.type-style('heading-01');
  margin-bottom: layout.$spacing-04;

  &.condensed {
    margin-bottom: layout.$spacing-01;
  }
}