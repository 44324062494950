@use '@carbon/layout';
@use '@carbon/grid';

.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: layout.$spacing-03;

  @include grid.breakpoint-up('md') {
    top: calc(layout.$spacing-03 + 50px);
    right: layout.$spacing-03;
    padding: 0;
    width: auto;
  }
}

.toast {
  @include grid.breakpoint-down('md') {
    width: 100%;
  }
}