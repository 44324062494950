@use '@carbon/layout';
@use '@carbon/type';

.heading {
  display: flex;
  align-items: center;
  margin-bottom: layout.$spacing-03;
}

.tag {
  margin-left: layout.$spacing-04;
}

.label {
  @include type.type-style('label-02');
  margin-right: layout.$spacing-04;
}