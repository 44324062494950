@use '@carbon/grid';

.container {
  display: flex;
  flex-direction: column;

  @include grid.breakpoint-up('md') {
    flex-direction: row;
  }
}

.profile {
  margin-top: 1rem;
  flex: 1 1 auto;

  @include grid.breakpoint-up('md') {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.profileHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.skeletonProfile {
  flex: 1;
  margin-top: 1rem;

  @include grid.breakpoint-up('md') {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.skeletonImage {
  width: 100%;
  height: 200px;

  @include grid.breakpoint-up('md') {
    width: 200px;
  }
}