@use '@carbon/type';
@use '@carbon/layout';
@use '@carbon/colors';
@use '@carbon/grid';

.container {
  display: inline-block;
}

.profile {
  padding: layout.$spacing-04;
  padding-right: layout.$spacing-04;

  @include grid.breakpoint-up('md') {
    padding: layout.$spacing-05;
    padding-right: layout.$spacing-06;
  }
}

.name {
  font-size: type.type-scale(2);
  color: colors.$white;

  @include grid.breakpoint-up('md') {
    font-size: type.type-scale(4);
  }
}

.email {
  font-size: type.type-scale(1);
  margin-top: layout.$spacing-02;
}

.role {
  color: colors.$white;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$gray-70;
  color: colors.$white;
  border-radius: 50%;
  margin-left: layout.$spacing-04;
  padding: layout.$spacing-03;

  @include grid.breakpoint-up('md') {
    display: inline-flex;
    margin-left: layout.$spacing-06;
    padding: layout.$spacing-04;
  }
}

.logout {
  width: 100%;
  min-height: 3.5rem;
  margin-top: layout.$spacing-04;
  justify-content: flex-start;
  background-color: colors.$gray-70;
}