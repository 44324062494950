@use '@carbon/layout';

.container {
  width: 100%;
  max-width: 100%;

  :global(.cds--inline-notification__title) {
    order: 2;
    margin-right: 0;
    margin-left: layout.$spacing-02;
  }
}

.warning {
  width: 100%;
  min-width: 100%;
  margin-bottom: layout.$spacing-05;
}