@use '@carbon/colors';

.container {
  display: flex;
}

.button {
  appearance: none;
  cursor: pointer;
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: 0px;
  padding: 1px;
  background: none;
  color: colors.$warm-gray-60;
  border: thin dotted colors.$warm-gray-20;

  &.noBorder {
    border: none;
  }
}