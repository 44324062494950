@use '@carbon/layout';
@use '@carbon/grid';

.container {
  // reset top margin
  margin-top: -(layout.$spacing-04);
}

.contactWrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: layout.$spacing-04;
  margin-top: layout.$spacing-04;

  @include grid.breakpoint-up('lg') {
    grid-template-columns: 1fr 1fr;
    column-gap: layout.$spacing-04;
  }

  &.fullWidth {
    grid-template-columns: 1fr !important;
  }
}