@use '@carbon/grid';
@use '@carbon/colors';
@use '@carbon/type';
@use '@carbon/layout';

.record {
  display: flex;
  justify-content: space-between; 

  @include grid.breakpoint-up('md') {
    justify-content: flex-start; 
  }
}

.vin {
  margin-top: layout.$spacing-01;;
  color: colors.$gray-50;
  @include type.type-style('helper-text-01');
}